export const DEFAULT_TRANSITION_TIMEOUT = 150;
export const DEFAULT_USER_NAME = 'Profile Name';
export const LOAN_TABLE_DATE_FORMAT = 'dd MMM yyyy';

export enum Currencies {
  EUR = 'EUR',
  USD = 'USD',
  CHF = 'CHF',
  IDR = 'IDR',
  DKK = 'DKK'
}

export enum LoanTypes {
  SME = 'SME',
  CONSUMER = 'CONSUMER'
}

export enum PlatformsIDs {
  CROWD4CASH = 'CROWD4CASH',
  FLEXFUNDING = 'FLEXFUNDING',
  PINJAMWINWIN = 'PINJAMWINWIN',
  BONDORA = 'BONDORA',
  EXALENDER = 'EXALENDER',
  FINBEE = 'FINBEE',
  VAURAUS = 'VAURAUS',
  ACREDIUS = 'ACREDIUS'
}

export enum ExaloanRatings {
  AA = 'AA',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F'
}

export enum ExaloanRatingsLevels {
  AA = 0,
  A = 1,
  B = 2,
  C = 3,
  D = 4,
  E = 5,
  F = 6
}

export enum RelativeValues {
  '3P' = '3P',
  '2P' = '2P',
  'P' = 'P',
  'N' = 'N',
  '2N' = '2N',
  '3N' = '3N'
}

export const currencyOptionsList = [Currencies.USD, Currencies.EUR, Currencies.CHF];
export const selectCurrencyOptions = [
  {
    value: Currencies.USD,
    label: Currencies.USD
  },
  {
    value: Currencies.EUR,
    label: Currencies.EUR
  },
  {
    value: Currencies.CHF,
    label: Currencies.CHF
  }
];

export const currencySymbols = {
  [Currencies.EUR]: '€',
  [Currencies.USD]: '$',
  [Currencies.CHF]: 'CHF',
  [Currencies.IDR]: 'Rp.',
  [Currencies.DKK]: 'Kr.'
};

export enum SortOrders {
  ASC = 'ASC',
  DESC = 'DESC'
}

export const defaultCurrencyOpt = {
  separator: ',',
  pattern: '! #',
  negativePattern: `! -#`
};

export const UTC_0_TIMEZONE = 'Etc/UTC';
