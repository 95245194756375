export const PAGES = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  UPDATE_PASSWORD: '/update-password',
  INVESTMENTS: '/investments',
  OUTSTANDING_BIDS: '/outstanding-bids',
  OPPORTUNITIES: '/opportunities',
  PORTFOLIO_BUILDER: '/portfolio-builder',
  NEW_ALLOCATION: '/portfolio-builder/new-allocation/',
  VIEW_ALLOCATION: '/portfolio-builder/view-allocation/:id',
  EDIT_ALLOCATION: '/portfolio-builder/edit-allocation/:id',
  PLATFORM_ANALYTICS: '/platform-analytics',
  STATISTICS: '/statistics',
  REPORTS: '/transactions',
  MARKETPLACE: '/marketplace',
  MARKETPLACE_CONSUMER: '/marketplace/consumer',
  MARKETPLACE_SME: '/marketplace/sme',
  HELP_CENTER: '/help-center',
  ERROR_404: '*',
  SERVER_ERROR: '/server-error',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  MY_PROFILE: '/my-profile',
  SETTINGS_AND_PRIVACY: '/settings-and-privacy',
  PASSWORD_RECOVERY: '/password-recovery',
  CONFIRM_PASSWORD_RECOVERY: '/confirm-password-recovery/',
  CHANGE_PASSWORD_SUCCESS: '/change-password-success/',
  ACCOUNT_VERIFIED: '/account-verified/',
  API_SETTINGS: '/api-settings',
  MARKETPLACE_CONSUMER_DETAILS: '/marketplace/consumer/:id',
  MARKETPLACE_SME_DETAILS: '/marketplace/sme/:id',
  OPPORTUNITY_DETAILS: '/opportunities/:id',
  INVESTMENT_DETAILS: '/investments/:id',
  BID_DETAILS: '/outstanding-bids/:id'
};

export const DYNAMIC_PAGES = {
  VIEW_ALLOCATION: (id: string): string => `/portfolio-builder/view-allocation/${id}`,
  EDIT_ALLOCATION: (id: string): string => `/portfolio-builder/edit-allocation/${id}`,
  MARKETPLACE_DETAILS: (id: string, isConsumer: boolean): string =>
    `/marketplace/${isConsumer ? 'consumer' : 'sme'}/${id}`,
  OPPORTUNITY_DETAILS: (id: string): string => `/opportunities/${id}`,
  INVESTMENT_DETAILS: (id: string): string => `/investments/${id}`,
  BID_DETAILS: (id: string): string => `/outstanding-bids/${id}`
};
