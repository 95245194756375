export type TableColumn = {
  id: string;
  label: string;
  isSortable: boolean;
  isOpen: boolean;
  sortOptions: Array<{ title: string; order: string }> | null;
  align?: ColumnAlignOptions;
  width?: number;
  isCheckbox?: boolean;
  style?;
};

export type TableHeaderHeadingColumn = {
  id?: string;
  label: string;
  align: ColumnAlignOptions;
  colSpan: number;
};

export type TableHeaderColumns = TableColumn[];
export type TableHeaderHeadingColumns = TableHeaderHeadingColumn[];

export enum ColumnAlignOptions {
  RIGHT = 'right',
  LEFT = 'left',
  CENTER = 'center'
}

export type THeadCssClasses = {
  tHeadClass?: string;
  checkboxRootClass?: string;
  tHeadCellClass?: string;
  tHeadLabelClass?: string;
  tHeadTopColumnCellClass?: string;
};

export type TableHeadProps = {
  columns: TableHeaderColumns;
  topHeadingColumns?: TableHeaderHeadingColumns;
  onSort: (type, order) => void;
  onSelectAllChange?: () => void;
  isSelectAllChecked?: boolean;
  isSelectAllDisabled?: boolean;
  classes?: THeadCssClasses;
  guttersLeft?: number;
  guttersRight?: number;
  onSetDropdownClass?: (id) => string;
};

export enum MenuPlacementOptions {
  TOP = 'top',
  BOTTOM = 'bottom',
  AUTO = 'auto'
}

export interface PaginationProps {
  currentPage: number;
  perPage: number;
  totalPages?: number;
  onChangePage: (e, page: number) => void;
  onChangePerPage?: (perPage: { value: number }) => void;
  options?: Array<{ value: number; label: string }>;
  menuPlacement?: MenuPlacementOptions;
  minMenuHeight?: number;
  maxMenuHeight?: number;
  selectStyles?: { menuMargin?: string; menuBoxShadow?: string };
  loadMorePagination?: boolean;
}

export type TBodyCssClasses = {
  tBodyClass?: string;
  tBodyCellClass?: string;
  tBodyCheckboxCellClass?: string;
  tBodyRowClass?: string;
  detailsContentClass?: string;
  detailsPathLinkClass?: string;
};
