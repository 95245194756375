import { TableHeaderColumns, ColumnAlignOptions } from 'src/interfaces/CustomTableShapes';
import { SortOrders } from 'src/constants/globalConstants';

export enum AllocationTypes {
  DEFAULT = 1,
  CUSTOM = 0
}

export enum AllocationTypesUppercase {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM'
}

export const AllocationTypeNames = {
  [AllocationTypes.DEFAULT]: 'default',
  [AllocationTypes.CUSTOM]: 'custom'
};

export enum RiskMeasureTypes {
  LOW = 1,
  BALANCED = 2,
  RETURN_ORIENTED = 3
}

export const RiskMeasureLowerCase = {
  1: 'LOW',
  2: 'BALANCED',
  3: 'RETURN_ORIENTED'
};

export enum AllocationStatuses {
  NON_ACTIVE = 'NON_ACTIVE',
  ACTIVE = 'ACTIVE',
  STOPPED = 'STOPPED'
}

export enum STATUS {
  NON_ACTIVE = 1,
  ACTIVE = 2,
  STOPPED = 0
}

export const allocationStatusNames = {
  [STATUS.ACTIVE]: 'Active',
  [STATUS.NON_ACTIVE]: 'Inactive',
  [STATUS.STOPPED]: 'Stopped'
};

export enum SortTypes {
  NAME = 'name',
  CREATED = 'created_at',
  MODIFIED = 'modified_at',
  UPDATED = 'updated_at',
  STATUS = 'status'
}

export enum PortfolioBuilderColIDs {
  NAME = 'name',
  ACTIONS = 'actions',
  CREATED = 'created_at',
  UPDATED = 'updated_at',
  STATUS = 'status',
  ALLOCATION = 'allocation'
}

export const PORTFOLIO_TABLE_HEADINGS_LIST: TableHeaderColumns = [
  {
    label: 'Name',
    id: PortfolioBuilderColIDs.NAME,
    isSortable: true,
    isOpen: false,
    width: 18,
    sortOptions: [
      { title: 'A - Z', order: SortOrders.ASC },
      { title: 'Z - A', order: SortOrders.DESC }
    ],
    align: ColumnAlignOptions.LEFT
  },
  {
    label: 'Actions',
    id: PortfolioBuilderColIDs.ACTIONS,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Created',
    id: PortfolioBuilderColIDs.CREATED,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'Most Recent', order: SortOrders.DESC },
      { title: 'Oldest', order: SortOrders.ASC }
    ],
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Modified',
    id: PortfolioBuilderColIDs.UPDATED,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'Last Modified', order: SortOrders.ASC },
      { title: 'Oldest', order: SortOrders.DESC }
    ],
    align: ColumnAlignOptions.CENTER
  },

  {
    label: 'Status',
    id: PortfolioBuilderColIDs.STATUS,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'Active', order: SortOrders.DESC },
      { title: 'Inactive', order: SortOrders.ASC }
    ],
    align: ColumnAlignOptions.CENTER
  },

  {
    label: 'Allocation',
    id: PortfolioBuilderColIDs.ALLOCATION,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  }
];

export enum COLUMN_IDS {
  EMPTY = 'EMPTY',
  SELECT_ALL = 'SELECT_ALL',
  NORTH_AMERICA = 'NORTH_AMERICA',
  ASIA = 'ASIA',
  ASIA_EXCLUDING_CHINA = 'ASIA_EXCLUDING_CHINA',
  EUROPE = 'EUROPE',
  SOUTH_AMERICA = 'SOUTH_AMERICA',
  LATIN_AMERICA = 'LATIN_AMERICA',
  AFRICA = 'AFRICA'
}

export const TABLE_HEAD_COLUMNS = [
  {
    id: COLUMN_IDS.EMPTY,
    name: ''
  },
  {
    id: COLUMN_IDS.SELECT_ALL,
    name: 'Select all'
  }
];

export enum RangeFiltersTypes {
  LOAN_AMOUNT = 'loanAmount',
  INTEREST_RATE = 'borrowerRate',
  TERM = 'term'
}

export const RANGE_FILTERS = (investorInfoCurrency: string) => [
  {
    id: RangeFiltersTypes.LOAN_AMOUNT,
    label: 'Loan Amount',
    step: 1,
    limits: {
      min: 0,
      max: 10000000
    },
    valueLabels: {
      isLabelInputs: true,
      maxThousandSeparator: true,
      minThousandSeparator: true,
      after: investorInfoCurrency ?? ''
    }
  },
  {
    id: RangeFiltersTypes.INTEREST_RATE,
    label: 'Interest Rate',
    limits: {
      min: 0,
      max: 50
    },
    step: 0.01,
    valueLabels: {
      isLabelInputs: true,
      maxThousandSeparator: false,
      minThousandSeparator: false,
      after: '%'
    }
  },
  {
    id: RangeFiltersTypes.TERM,
    label: 'Term',
    limits: {
      min: 0,
      max: 60
    },
    step: 1,
    valueLabels: {
      isLabelInputs: true,
      after: 'months'
    }
  }
];

export enum Parts {
  CURRENCY = 'currency',
  CREADIT_RATING = 'creditRating',
  LOAN_PURPOSES = 'loanPurposes',
  SME_SECTORS = 'smeSectors',
  PLATFORMS = 'platforms',
  LOAN_SIZE_CONSTRAINTS = 'loanSizeConstraints',
  RELATIVE_VALUE = 'relativeValueChartData'
}

export enum PartsReverse {
  currencies = 'currency',
  grades = 'creditRating',
  platforms = 'platforms',
  purposes = 'loanPurposes',
  loan_size_constraints = 'loanSizeConstraints',
  sectors = 'smeSectors',
  relative_value = 'relativeValueChartData'
}

export const DEFAULT_INVESTMENT_PERCENTAGE = 100;
export const MIN_INVESTMENT_PERCENTAGE = 1;

const LOAN_TYPE_CASES = {
  CONSUMER: 'CONSUMER',
  SME: 'SME'
};

export const LOAN_TYPE_OPTIONS = [
  { value: LOAN_TYPE_CASES.CONSUMER, label: 'Consumer Loan' },
  { value: LOAN_TYPE_CASES.SME, label: 'SME Loan' }
];
